/* Basic reset */
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }

  .hero-section {
    /* Set background image and make it cover the entire section */
    background-image: url('../../Images/hero-img-empower.jpg'); /* Replace with your image URL */
    background-position: center;
    background-size: cover;
   height:90vh; /* Full viewport height */
    display: flex;
    flex-direction: column;
    align-content: space-between;
    justify-content: center;
    position: relative;
  
  }
  .hero-title{
    margin-left:0;
    margin-right:0;
  }
  .top-container{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    text-align: center;
    height: 100%;
  }


.first-container{
    padding-top:2%;
    color:white;
    justify-content: center;
    text-align: center;
}
.last-container{

    display: flex;
    justify-content: center;
    flex-direction: column;
}


  .text-overlay {
    text-align: center;
    color: white;
   padding-top: 4%;
     /* Optional: adds a subtle blur effect to the background */
  }

  .empower-text{
    color:white;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    border-radius:10px;
    margin-bottom:3%;
   padding:2%;
 
  }
  
 
  
  .subtitle {
    font-size: 1.5rem;
  }
  

  //Our Story Section
/* Global Reset */

  
  .about-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: $sectionpadding;
    background-repeat: no-repeat;
    position: relative;
    background-position: top left, left center, bottom left, top right, bottom right;
    background-image:
      url('../../Assets/LightBulb.png'),
      url('../../Assets/SolarPanel.png'),
      url('../../Assets/PlugElectric.png'),
      url('../../Assets/WaterDrop.png'),
      url('../../Assets/Lighting.png');
  }
  
  .story-title {
    display: flex;
    justify-content: center;
    padding-bottom: 2%;
    font-weight: bold;
    font-size: 2rem; /* Adjust font size for consistency */
  }
  
  .story-description {
    display: flex;
    justify-content: center;
    font-weight: bold;
    text-align: center;
    padding: 2% 3%; /* Add padding to balance out text */
  }
  /* .empower-description{
    font-size: 26px;
  }
   */
  .story-text {
    padding-top: 3%;
    font-weight: bold;
    padding-left: 3%;
    padding-right: 3%;
    font-size: 1rem; /* Set font size for readability */
  }
  
  .story-img {
    width: 100%;
    margin-left: 2%;
  }
  .button-container{
    display: flex;
    justify-content: center;
  }

  //Our Story Section

  //Sustainable Section

  .sustainable-container{
    background-image: url('../../Images/bulb-overlay.jpg');
    background-repeat: none;
    background-size: cover;
    height:600px;
    justify-content: center;
    color: white;
 padding: 1% 2%;
}
.empower-blurb{
    background-color: rgba(0, 0, 0, 0.7);
    border-radius: 10px;
    justify-content: left;
    text-align: left;
    padding:2%;
    margin-bottom: 1%;


}
.empower-description{
    margin-bottom: 0!important;
    font-size: 26px;

}



  //////
  /// 
  
  /* Media Queries */


  @media (max-width: 1200px) {
  .sustainable-container{
    height:auto!important;
  }
  .about-container{
    background-image: none;
   }

    }

  @media (max-width: 1024px) {
    .hero-section {
      align-content: space-around;
        height:auto; /* Full viewport height */
        text-align: center;
     
       }
       .last-container{
         margin-top:20%;
       }
    .empower-description{
      font-size: 20px;
    }
        .sustainable-container {
          padding: 4% 2%;
          height:auto;
        }

        .about-container{
          background-image: none;
         }
         .story-img{
          margin-left: 0;
         }
         .story-button{
          width:100%;
          font-size: 18px;
         }

    }
    
    /* Small devices (max-width 502px) */
  @media only screen and (max-width: 502px) {
      .sustainable-container{
       height:auto;
       padding-bottom:8%;
      }
      .empower-description{
       font-size:14px;
      }
      .about-container {
        background-image: none; /* Remove background images on small screens */
      }
    
      .story-title {
        font-size: 1.5rem; /* Smaller font size for smaller devices */
      }
    
      .story-text {
        font-size: 0.9rem; /* Adjust font size for readability */
      }
    
      .story-button {
        width: 100%; /* Make the button full-width on smaller screens */
      }
      .empower-description{
        font-size: 14px;
        text-align: center;
      }
      .empower-blurb{
        
        margin-bottom: 2%;
    
    
    }
 
     }

     @media (max-width: 480px) {
      .top-container{
       justify-content: space-around;
      }
   
     
     }
     
  