.energy-container{
padding:$sectionpadding;
    background-image: url('../../Images/background-challenge-section.png');
    
}
.empower-graphic{
    width:50%;
    object-fit: contain;
    padding: 2% 0;
   }
      
   .solar-container{
    padding:$sectionpadding;
   }

   @media (max-width: 1024px) {
   
.innovative-title{
    justify-content: center;
}

.empower-graphic{
    width:100%;
    object-fit: contain;
    padding: 2% 0;
   }
   .empower-graphic-text{
    text-align: center;
   }
       
   }
   
@media (max-width: 650px) {
   
   .empower-graphic{
    width:100%;
   }
   .empower-graphic-text{
   font-size: 20px;
   }
      
  }
  
    