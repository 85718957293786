// COLORS //
// Primary //
$green: #49834C;
$yellow: #E3B23C;
$navy: #3D518C;

// FONTS //
h1, h2, h3, h4, h5 {
    font-family: 'Open Sans';
    font-weight: bold;
}

h1{
    font-size: 55px;
    text-shadow: 1px 1px 1px black;
    font-weight: bold;
  }

  h4{
    font-size: 32px;
  }


  p{
    font-size: 18px;
 margin-bottom:2%;
  }

  @media (max-width: 1024px) {
   
    h1 {
        font-size: 35px!important;
        text-align: center;
      text-shadow: none;
       
      }
      h4{
        font-size: 24px!important;
        text-align: center;
      }
      p{
        text-align: center;
      }
      .story-button{
        width:80%;
      }
  }


  @media (max-width: 780px) {
   
    h1 {
      font-size: 30px!important;
      text-align: center;
    text-shadow: none;
     
    }
    h3{
      font-size: 16px!important;
    }
    h4{
      font-size: 20px!important;
      text-align: center;
    }
    h5{
      font-size: 24px;
      text-align: center;
    }
    p{
      text-align: center;
    }
    .story-button{
      width:80%;
    }
  }

  @media (max-width: 500px) {
   
    h1 {
        font-size: 22px!important;
        text-align: center;
      text-shadow: none;
       
      }
      
      h4{
        font-size: 18px!important;
        text-align: center;
      }
      h5{
        font-size: 16px;
        text-align: left;
      }
      p{
        text-align: center;
      }
     
      
  }


  // Padding for between sections

  $sectionpadding: 4% 2%;
  
//BUTTON STYLES
.story-button {
  margin-top: 3%;
  background-color: var(--green, $green); /* Use CSS variable for green color */
  border: 1px solid var(--green, $green);
font-family: Montserrat;
  justify-content: center;
font-weight:bold;
  font-size: 29px;
  cursor: pointer;
  text-align: center;
  text-decoration: underline;
}
  .story-button:hover{
    background-color: $navy;
    text-decoration: underline;
  }