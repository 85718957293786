.nav-container{
    color: white;
}
.main-logo{
    width:'100px';
    margin-left: 10%;
}
.nav-link{
    color: $navy;
}

.nav-link:hover{
    background-color: $green;
    color:white;
    border-radius: 8px;
}

.nav-link-spacing{
    margin:0 2%;
}

.links{
    font-size: 18px;
    font-weight:bold;
  
}


  .navbar-nav .nav-link.active, .navbar-nav .nav-link.show {
    color: #3F6E41; 
    font-weight: bold;
    border-radius: 10px; 
  }
 /* Hover Effect for Active or Show Links */
.navbar-nav .nav-link.active:hover,
.navbar-nav .nav-link.show:hover {
  color: white; 
  border-radius: 10px;/* Change to a darker color on hover */
  /* Optional: Add a light background on hover */
 /* Optional: Underline the link on hover */
}
  

    // Media Queries //
    @media only screen and (min-width: 992px) and (max-width:1200) {
        .nav-link{
            font-size: 14px;
        }
     
      }
  
  // Media Queries //
@media only screen and (min-width: 992px) {
    .nav-height{
     height: 10vh;
    }
 
  }

