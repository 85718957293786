
.footer{
    background-color: $green;
    color:'white';
    margin-top: auto; 
    font-family: 'Open Sans';
}

.logo-links-container{
    justify-content: space-between;
    padding:1%; 
}

.footer-logo-container{
    justify-content: center;
    display: flex;
}
.footer-logo{
    width: 200px;
}
.footer-links-container{
   justify-content: center;
   display: flex;
   align-items: center;
}
.footer-links-ul{
    list-style-type: none;
    text-decoration: underline;
    text-decoration-color: white;
    text-align: right;
}
.footer-link-text{
    color:white;
    text-decoration: none;
   
}
.footer-divider{
    border: 1px solid white;
    width: 90%;
    justify-content: center;
    display: flex;
   
}

.social-media-icons-container{
    display: flex;
    justify-content: center;
    color:'white';
}

.bi-facebook, .bi-twitter-x, .bi-instagram,.bi-envelope{
    color:white;
    font-size: 24px;
    padding-left: 1%;
    padding-right: 1%;
}

.copyright-container{
    display: flex;
    justify-content: center;
    color: white;
    padding-top: 3%;
    padding-bottom:2%;
}



// Media Queries //
@media only screen and (max-width: 991px) {
    .footer-links-ul{
     padding: 0!important;
        text-align: center;
    
    }

    .footer-logo{
        width: 260px;
    }
  }


@media only screen and (max-width: 600px) {
    .footer-links-ul{
     padding: 0!important;
        text-align: center;
    
    }

    .footer-logo{
        width: 260px;
    }
  }